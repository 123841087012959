/* eslint-disable */
export default {
  name: "Max Mustermann",
  invoiceAddress:
    "<p>Max Mustermann<br/>\
  Muster Firma GbR<br/>\
  Musterstraße 2<br/>\
  15366 Musterstadt</p>",
  shippingAddress:
    "<p>musterdomain.de • Muster Firma GbR<br/>\
  Musterstraße 2 • 15366 Musterstadt</p>",
  discount: 0,
  ust: 7,
};
