export default {
  invoiceDate: new Date().toString(),
  shippingDate: new Date().toString(),
  orderDate: new Date().toString(),
  invoiceNumber: "",
  porto: "5,00",
  finalText: "",
  articles: [],
  services: [],
};
