import React from "react";
import Footer from "../../components/Footer";

export default function AGB() {
  return (
    <div>
      <div className="invoice-app-container ">
        <p>
          This is a private page please do not use if you don't know the owner.
        </p>
      </div>
      <Footer />
    </div>
  );
}
